import { Injectable } from '@angular/core';

import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
  } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '@app/core/authentification/auth.service';

  /**
   * Intercepte les request sortant et rajoute le token d'authentification
   */
  @Injectable()
  export class TokenInterceptor implements HttpInterceptor {

    constructor(public auth: AuthService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if (this.auth.credentials && this.auth.credentials.token) {
        request = request.clone({
          setHeaders: {
            'Content-Type': 'application/json',
            'Authorization': this.auth.credentials.token
          }
        });
      }

      return next.handle(request);
    }
  }
