import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/core/authentification/auth.service';
import { Router, NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  isCollapsed = true;

  constructor(
    private router: Router,
    public authService: AuthService
  ) { }

  ngOnInit() {
  }

  logout() {
    this.authService.logout();

      // Set our navigation extras object
      // that passes on our global query params and fragment
      const navigationExtras: NavigationExtras = {
        queryParamsHandling: 'preserve',
        preserveFragment: true
      };

      // Redirect the user
      this.router.navigate(['/login'], navigationExtras);
  }

  get username(): string | null {
    const credentials = this.authService.credentials;
    return credentials ? credentials.username : '';
  }

}
