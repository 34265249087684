import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BsDropdownModule, CollapseModule } from 'ngx-bootstrap';
import { Router } from '@angular/router';

// https://fontawesome.com/icons?d=gallery&m=free
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

import { AppComponent } from '@app/app.component';
import { AppRoutingModule } from '@app/app-routing.module';
import { NavbarComponent } from '@app/navbar/navbar.component';
import { LoginComponent } from '@app/login/login.component';
import { LoginRoutingModule } from '@app/login/login-routing.module';
import { SignupComponent } from '@app/signup/signup.component';
import { TokenInterceptor } from '@app/token.interceptor';
import { PageNotFoundComponent } from '@app/not-found.component';
import { PageNotAuthorizedComponent } from '@app/not-authorized';
import { FooterComponent } from '@app/footer/footer.component';
import { BannerComponent } from '@app/banner/banner.component';
import { ContainerComponent } from '@app/container/container.component';
import { HomeComponent } from '@app/home/home.component';
import localeFrCH from '@angular/common/locales/fr-CH';
import localeFrCHExtra from '@angular/common/locales/extra/fr-CH';
import { registerLocaleData, LocationStrategy, HashLocationStrategy } from '@angular/common';

import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';

registerLocaleData(localeFrCH, localeFrCHExtra);

// Add an icon to the library for convenient access in other components
library.add(fas, far);

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    LoginComponent,
    // MembresComponent,
    // MembreDetailComponent,
    PageNotFoundComponent, PageNotAuthorizedComponent,
    SignupComponent,
    FooterComponent,
    BannerComponent,
    ContainerComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule, ReactiveFormsModule,
    HttpClientModule,
    TableModule, InputTextModule, DialogModule, ButtonModule,
    MenuModule, MenubarModule,
    BsDropdownModule.forRoot(), CollapseModule.forRoot(),
    FontAwesomeModule,
    LoginRoutingModule,
    AppRoutingModule, // <-- Doit être placé après les modules contenant leur propre route
  ],
  providers: [
    {
      provide: LOCALE_ID, useValue: 'fr-CH'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    // {
    //   provide: LocationStrategy,
    //   useClass: HashLocationStrategy
    // }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
    // Diagnostic only: inspect router configuration
    constructor(router: Router) {
      // console.log('Routes: ', JSON.stringify(router.config, undefined, 2));
    }
 }
