import { HomeComponent } from '@app/home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CanDeactivateGuardService } from '@app/can-deactivate-guard.service';
import { SelectivePreloadingStrategy } from '@app/selective-preloading-strategy';
import { PageNotFoundComponent } from '@app/not-found.component';
import { SignupComponent } from '@app/signup/signup.component';
import { PageNotAuthorizedComponent } from '@app/not-authorized';
import { AuthGuardService } from '@app/core/authentification/auth-guard.service';

const routes: Routes = [
  // { path: 'signup', component: SignupComponent },
  { path: 'home', component: HomeComponent },
  { path: 'notAuthorized', component: PageNotAuthorizedComponent },
  {
    path: 'admin',
    loadChildren: 'app/admin/admin.module#AdminModule',
    canLoad: [AuthGuardService]
  },
  { path: '',   redirectTo: '/admin', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes,
      {
        enableTracing: false, // <-- debugging purposes only
        preloadingStrategy: SelectivePreloadingStrategy,
      })
  ],
  exports: [RouterModule],
  providers: [
    CanDeactivateGuardService,
    SelectivePreloadingStrategy
  ]
})
export class AppRoutingModule { }
