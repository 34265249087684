import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '@app/login/login.component';
import { AuthGuardService } from '@app/core/authentification/auth-guard.service';
import { AuthService } from '@app/core/authentification/auth.service';

const routes: Routes = [
  { path: 'login', component: LoginComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [
    AuthGuardService,
    AuthService
  ]
})
export class LoginRoutingModule { }
