import { Injectable } from '@angular/core';
import { Observable ,  of } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import * as moment from 'moment';
// import * as jwt from 'jsonwebtoken';
import * as jwt_decode from 'jwt-decode';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

export interface Credentials {
  // Customize received credentials here
  username: string;
  token: string;
  datetime: Date;
  role: string;
}

export interface LoginContext {
  usernameEmail: string;
  password: string;
  remember?: boolean;
}

interface ResponseToken extends Response {
  token: string;
}

const credentialsKey = 'currentUser';

@Injectable()
export class AuthService {
  private serverUrl: string = environment.serverApiUrl;
  private urlApi = this.serverUrl + '/login';

  private _credentials: Credentials | null;

  // store the URL so we can redirect after logging in
  redirectUrl: string;

  constructor( private http: HttpClient ) {
    // set token if saved in local storage
    const savedCredentials = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey);
    if (savedCredentials) {
      this._credentials = JSON.parse(savedCredentials);
      console.log('Test: ', moment(this._credentials.datetime).fromNow());
      const nbHeure = moment.duration(moment().diff(moment(this._credentials.datetime))).as('hours');
      if (!this._credentials.datetime || nbHeure > 12) {
        this.logout();
      }
    }
   }

  login(context: LoginContext): Observable<boolean> {
    return this.http.post<ResponseToken>(this.urlApi, context, httpOptions).pipe(map((response: ResponseToken) => {
        // login successful if there's a jwt token in the response
        const token = response.token;
        if (token) {
          const decoded: any = jwt_decode(token);
          // console.log(decoded.data);

          // set token property
          const data: Credentials = {
            username: context.usernameEmail,
            token: token,
            datetime: new Date(),
            role: decoded.data.role
          };
          this.setCredentials(data, context.remember);

          // return true to indicate successful login
          return true;
        } else {
            // return false to indicate failed login
            return false;
        }
    }));
  }

  /**
   * Logs out the user and clear credentials.
   * @return {Observable<boolean>} True if the user was logged out successfully.
   */
  logout(): Observable<boolean> {
    // Customize credentials invalidation here
    this.setCredentials();
    return of(true);
  }

  /**
   * Checks is the user is authenticated.
   * @return {boolean} True if the user is authenticated.
   */
  isAuthenticated(): boolean {
    return !!this.credentials;
  }

  /**
   * Gets the user credentials.
   * @return {Credentials} The user credentials or null if the user is not authenticated.
   */
  get credentials(): Credentials | null {
    return this._credentials;
  }

  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   * @param {Credentials=} credentials The user credentials.
   * @param {boolean=} remember True to remember credentials across sessions.
   */
  private setCredentials(credentials?: Credentials, remember?: boolean) {
    this._credentials = credentials || null;

    if (credentials) {
      const storage = remember ? localStorage : sessionStorage;
      storage.setItem(credentialsKey, JSON.stringify(credentials));
    } else {
      sessionStorage.removeItem(credentialsKey);
      localStorage.removeItem(credentialsKey);
    }
  }

}
