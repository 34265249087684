import { Component, OnInit } from '@angular/core';

import { MenuItem } from 'primeng/api';

// TEST
// export class PrimeCar implements Car {
//   constructor(public vin?, public year?, public brand?, public color?) {}
// }


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Saules-info';

  // displayDialog: boolean;
  // car: Car = new PrimeCar();
  // selectedCar: Car;
  // newCar: boolean;
  // cars: Car[];
  // cols: any[];

  items: MenuItem[];

  constructor() { }

  ngOnInit() {
    this.items = [{
      label: 'File',
      items: [
          {label: 'New', icon: 'fa fa-plus'},
          {label: 'Open', icon: 'fa fa-download'}
      ]
    },
    {
        label: 'Edit',
        items: [
            {label: 'Undo', icon: 'fa fa-refresh'},
            {label: 'Redo', icon: 'fa fa-repeat'}
        ]
    }];

      // this.carService.getCarsSmall().then(cars => this.cars = cars);

      // this.cols = [
      //     { field: 'vin', header: 'Vin' },
      //     { field: 'year', header: 'Year' },
      //     { field: 'brand', header: 'Brand' },
      //     { field: 'color', header: 'Color' }
      // ];
  }

  // showDialogToAdd() {
  //     this.newCar = true;
  //     this.car = new PrimeCar();
  //     this.displayDialog = true;
  // }

  // save() {
  //     const cars = [...this.cars];
  //     if (this.newCar) {
  //         cars.push(this.car);
  //     } else {
  //         cars[this.findSelectedCarIndex()] = this.car;
  //     }
  //     this.cars = cars;
  //     this.car = null;
  //     this.displayDialog = false;
  // }

  // delete() {
  //     const index = this.findSelectedCarIndex();
  //     this.cars = this.cars.filter((val, i) => i !== index);
  //     this.car = null;
  //     this.displayDialog = false;
  // }

  // onRowSelect(event) {
  //     this.newCar = false;
  //     this.car = {...event.data};
  //     this.displayDialog = true;
  // }

  // findSelectedCarIndex(): number {
  //     return this.cars.indexOf(this.selectedCar);
  // }
}
