import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService, LoginContext } from '@app/core/authentification/auth.service';
import { finalize } from 'rxjs/operators';
import { environment } from '@env/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  titre = 'Admin';
  loginForm: FormGroup;
  version: string = environment.version;
  error: string;
  isLoading = false;

  constructor(
    public authService: AuthService,
    public router: Router,
    private formBuilder: FormBuilder,
  ) {
   }

  ngOnInit() {
    this.createForm();
  }

  private createForm() {
    this.loginForm = this.formBuilder.group({
      usernameEmail: ['', Validators.required],
      password: ['', Validators.required],
      remember: true
    });
  }

  login() {
    this.isLoading = true;

    this.authService.login(this.loginForm.value)
    .pipe(finalize(() => {
      this.loginForm.markAsPristine();
      this.isLoading = false;
    }))
    .subscribe(tst => {
      if (this.authService.isAuthenticated()) {
        // console.log(this.authService.getToken());
        // TODO Stocker le token dans un cookie

        // Get the redirect URL from our auth service
        // If no redirect has been set, use the default
        const redirect = this.authService.redirectUrl ? this.authService.redirectUrl : '/admin';

        // Set our navigation extras object
        // that passes on our global query params and fragment
        const navigationExtras: NavigationExtras = {
          queryParamsHandling: 'preserve',
          preserveFragment: true
        };

        // Redirect the user
        this.router.navigate([redirect], navigationExtras);
      }
    }, error => {
      console.error(`Login error: ${error}`);
      this.error = error;
    });
  }

  logout() {
    this.authService.logout();
  }

}
